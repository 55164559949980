import Cookies from 'js-cookie'
import { getAccountInfo, isInApp } from './bridge'
import { Toast } from 'antd-mobile'

const Util = {
  isLogin: () => {
    if (isInApp()) {
      const token = getAccountInfo()
      if (token) {
        return true
      }
    }
    const token = Cookies.get('token')
    const uid = Cookies.get('userId')
    return !!(token && uid)
  },
  getToken() {
    if (isInApp()) {
      const token = getAccountInfo()
      if (token) {
        return token
      }
    }
    const token = Cookies.get('token')
    const uid = Cookies.get('userId')
    return {
      token,
      uid,
    }
  },
  removeUserinfo() {
    Cookies.remove('token')
    Cookies.remove('userId')
  },
  setLoginCookie(data) {
    Cookies.set('token', data.token, { expires: 7 })
    Cookies.set('userId', data.id, { expires: 7 })
  },

  setUserNameAndPassword(data) {
    if (Cookies.get('userName') == data.userName && !!Cookies.get('userName')) {
      return
    } else {
      Cookies.set('userName', data.userName, { expires: 7 })
    }
    if (Cookies.get('password') == data.password && !!Cookies.get('password')) {
      return
    } else {
      Cookies.set('password', data.password, { expires: 7 })
    }
  },

  removeUserNameAndPassword() {
    Cookies.remove('userName')
    Cookies.remove('password')
  },

  getUserNameAndPassword() {
    // if (isInApp()) {

    //   const token = getAccountInfo()
    //   const userName = Cookies.get('userName')
    //   const password = Cookies.get('password')
    //   debugger
    //   if (token) {
    //     return {
    //       userName,
    //       password,
    //       token,
    //     }
    //   } else {
    //     return {
    //       userName,
    //       password,
    //     }
    //   }
    // }

    const userName = Cookies.get('userName')
    const password = Cookies.get('password')
    return {
      userName,
      password,
    }
  },

  isPhoneNumber(phone) {
    if (!/^1[3456789]\d{9}$/.test(phone)) {
      return false
    } else {
      return true
    }
  },
  getQueryParams(name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i'),
      url = window.location.href
    const search = window.location.search
      ? window.location.search.split('?')[1]
      : url.split('?')[1]
    if (!search) {
      return ''
    }
    var r = search.match(reg)
    if (r != null) return unescape(decodeURI(r[2]))
    return null
  },
  priceFormat(num, needAddIcon) {
    const prefix = needAddIcon ? '+' : ''
    if (num === '--') return prefix + num
    if (!num) return prefix + '0.0'
    var c =
      num.toString().indexOf('.') !== -1
        ? num.toLocaleString()
        : num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    return prefix + (c || '0.0')
  },
  copyToClip(text, hint) {
    const u = navigator.userAgent
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
    if (isAndroid || (!isAndroid && !isiOS)) {
      var txt = document.createElement('textarea')
      txt.style = 'position:absolute;top:-9999px;left:-9999px;'
      txt.setAttribute('id', 'selector')
      txt.setAttribute('readonly', 'readonly')
      txt.innerHTML = text
      document.body.appendChild(txt)
      txt.select()
      document.execCommand('copy', false, null)
    }
    if (isiOS) {
      const txt = document.createElement('a')
      txt.setAttribute('id', 'selector')
      txt.setAttribute('style', 'position:absolute;top:-9999px;left:-9999px;')
      txt.innerHTML = text
      document.body.appendChild(txt)
      const copyDOM = document.querySelectorAll('#selector')
      const range = document.createRange()
      range.selectNode(copyDOM[0])
      window.getSelection().removeAllRanges()
      window.getSelection().addRange(range)
      document.execCommand('copy')
    }
    const e_selector = document.getElementById('selector')
    document.body.removeChild(e_selector)
    if (hint) Toast.show(hint)
  },
  fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
    })
  },
  base64ToFile(dataurl, fileName) {
    return new Promise((resolve, reject) => {
      try {
        let arr = dataurl.split(',')
        let mime = arr[0].match(/:(.*?);/)[1]
        let bstr = atob(arr[1])
        let n = bstr.length
        let u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        const theBlob = new Blob([u8arr], { type: mime })
        theBlob.lastModifiedDate = new Date() // 文件最后的修改日期
        theBlob.name = fileName // 文件名
        resolve(
          new File([theBlob], fileName, {
            type: theBlob.type,
            lastModified: Date.now(),
          })
        )
      } catch (err) {
        reject(err)
      }
    })
  },
  triggerCustomStorageEvent(key, value) {
    const storageEvent = new CustomEvent('localStorageChange', {
      detail: { key, value },
    })
    window.dispatchEvent(storageEvent)
  },
  convertBase64UrlToBlob(urlData) {
    let arr = urlData.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  },
  /**
   *
   * @param {File} file - 需要压缩的图片文件
   * @param {{
   *  width: number,
   *  height: number,
   *  quality: number,
   * }} obj - 压缩参数
   * @param {Function} callback - 压缩完成后的回调函数 callback(file)
   */
  compressImg(file, obj, callback) {
    const ready = new FileReader()
    ready.readAsDataURL(file)
    ready.onload = function () {
      const path = this.result
      // ↓压缩
      const img = new Image()
      img.src = path
      img.onload = function () {
        const that = this
        // 默认按比例压缩
        let w = that.width,
          h = that.height,
          scale = w / h
        w = obj.width || w
        h = obj.height || w / scale
        let quality = 0.7 // 默认图片质量为0.7
        //生成canvas
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        // 创建属性节点
        const anw = document.createAttribute('width')
        anw.nodeValue = w
        const anh = document.createAttribute('height')
        anh.nodeValue = h
        canvas.setAttributeNode(anw)
        canvas.setAttributeNode(anh)
        ctx.drawImage(that, 0, 0, w, h)
        // 图像质量
        if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
          quality = obj.quality
        }
        // quality值越小，所绘制出的图像越模糊
        const base64 = canvas.toDataURL('image/jpeg', quality)
        // // 转换为Blob数据
        const BlobData = Util.convertBase64UrlToBlob(base64)
        // // 转换为file数据
        const this_file = new File([BlobData], file.name, {
          type: file.type,
        })
        // 回调函数返回file的值
        callback(this_file)
        // ↑压缩
      }
    }
  },
}
export default Util
