import React, { Component, Suspense } from 'react'
//HashRouter,支持#识别,http://localhost:8080/#/Page3/
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
//单页BrowserRouter
import RouterConfig from './router'
import ScrollToTop from './scrollToTop'

import 'antd-mobile-v2/dist/antd-mobile.less'

import API from './service/api'

import { getFetch } from './service/request'
import Util from './common/util'
class Page extends React.Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    document.title = this.props.title
    let needCheckToken = this.props.auth
    let checkTokenUrl = API.checkToken
    let token = Util.getToken().token
    let header = {
      token: token,
    }
    // let header = {
    //   channel: 'test',
    // }
    if (needCheckToken) {
      getFetch(checkTokenUrl, {}, header)
        .then((data) => {
          if (data.code == 200) {
            //  Toast.show('校验成功')
            console.log('校验成功')
          } else {
            window.location.href = './#/login'
          }

          // Toast.show('登录成功')

          //  + data.token
          // this.props.loginSuccess && this.props.loginSuccess()
        })
        .catch((err) => {
          alert(err.message)
          window.location.href = './#/login'
        })
    }
  }
  componentWillUnmount() {
    if (
      this.props.location.pathname.indexOf(
        '/precisionMedicineGuidelineReport'
      ) !== -1
    ) {
      const { generate, uid } = this.props.match.params
      if (generate && generate === '1') {
        if (uid) {
          window.location.href = './#/precisionMedicine?uid=' + uid
        }
      } else {
        window.location.href = './#/precisionMedicine'
      }
    }
  }
  render() {
    const PageComponent = this.props.component
    return <PageComponent {...this.props} />
  }
}
export default class App extends Component {
  constructor(props) {
    super(props)
  }
  componentDidUpdate(prevProps) {
    console.log('componentDidUpdate', prevProps)
  }
  render() {
    const handlePageChange = () => {
      console.log('handlePageChange')
      window.scrollTo(0, 0)
    }
    return (
      <Router onUpdate={handlePageChange}>
        <ScrollToTop />
        <Suspense fallback={null}>
          <Switch>
            {RouterConfig.map((item) => {
              return (
                <Route
                  key={item.path}
                  path={item.path}
                  title="Login"
                  render={(props) => {
                    return (
                      <Page
                        {...item}
                        {...props}
                        component={item.component}
                        title={item.name}
                        auth={item.auth}
                      />
                    )
                  }}
                />
              )
            })}
          </Switch>
        </Suspense>
      </Router>
    )
  }
}
