/* eslint-disable no-throw-literal */
import 'whatwg-fetch'
import Util from '../common/util'
import Cookies from 'js-cookie'
import Prefix from './prefix'
import { Modal } from 'antd-mobile-v2'
import { getAccountInfo } from '../common/bridge'
import { Headers } from 'whatwg-fetch'
alert = (message) => {
  Modal.alert('', message, [
    {
      text: '确定',
    },
  ])
}
const postRequest = async (url, params = {}, headerParams = {}) => {
  const actoken = sessionStorage.getItem('resources')
  headerParams['actoken'] = actoken

  let options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // ...token,
      ...headerParams,
    },
    body: JSON.stringify({
      ...params,
      // ...token,
    }),
  }
  const fetchUrl =
    url.indexOf('http') === 0 ? url : Prefix.domain + Prefix.path + url
  return await fetch(fetchUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        const error = new Error(response.statusText)
        throw {
          err: error,
          message: '网络异常,请稍后重试! 错误代码：' + error.desc,
        }
      }
    })
    .then((res) => {
      // console.log("response.success", res);
      if (res.code === 403) {
        goLogin()
        throw {}
      } else if (res.access_token && res.token_type && res.expires_at) {
        Util.setLoginCookie(res)
        return {
          message: '登录成功',
        }
      } else if (res.code === 200) {
        return res.data
        // if (res.data) {
        //   return res.data
        // } else {
        //   return res
        // }
      } else {
        alert(res.desc)
        throw res
      }
    })
    .catch((err) => {
      console.log('err', err)
      throw {
        message: err ? err.msg : '',
      }
    })
}

const postRequestWithHeaderObj = async (
  url,
  params = {},
  headerParams = new Headers()
) => {
  // var token = await getAccountInfo()
  // if (isEmptyObject(token)) {
  //     token = Util.getToken()
  // }
  headerParams.append('Content-Type', 'application/json')
  let options = {
    method: 'POST',
    headers: headerParams,
    body: JSON.stringify({
      ...params,
      // ...token,
    }),
  }
  const fetchUrl =
    url.indexOf('http') === 0 ? url : Prefix.domain + Prefix.path + url
  return await fetch(fetchUrl, {
    method: 'POST',
    headers: headerParams,
    body: JSON.stringify({
      ...params,
      // ...token,
    }),
  })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        const error = new Error(response.statusText)
        throw {
          err: error,
          message: '网络异常,请稍后重试! 错误代码：' + error.desc,
        }
      }
    })
    .then((res) => {
      // console.log("response.success", res);
      if (res.code === 403) {
        goLogin()
        throw {}
      } else if (res.access_token && res.token_type && res.expires_at) {
        Util.setLoginCookie(res)
        return {
          message: '登录成功',
        }
      } else if (res.code === 200) {
        return res.data
      } else {
        alert(res.desc)
        throw res
      }
    })
    .catch((err) => {
      console.log('err', err)
      throw {
        message: err ? err.msg : '',
      }
    })
}

const postFormRequest = async (url, data, headerParams = {}) => {
  const actoken = sessionStorage.getItem('resources')
  headerParams['actoken'] = actoken

  let options = {
    method: 'POST',
    headers: {
      // "Content-Type": 'multipart/form-data',
      ...headerParams,
    },
    body: data.file,
  }
  console.log(options.body.get('file'))
  const fetchUrl =
    url.indexOf('http') === 0 ? url : Prefix.domain + Prefix.path + url
  return await fetch(fetchUrl, options)
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        const error = new Error(response.statusText)
        throw {
          err: error,
          message: '网络异常,请稍后重试! 错误代码：' + error.desc,
        }
      }
    })
    .then((res) => {
      // console.log("response.success", res);
      if (res.code === 200) {
        return res.data
      } else {
        alert(res.desc)
        throw res
      }
    })
    .catch((err) => {
      console.log('err', err)
      throw {
        message: err && err.msg ? err.msg : null,
      }
    })
}
function obj2params(obj) {
  var result = ''
  var item
  for (item in obj) {
    result += '&' + item + '=' + encodeURIComponent(obj[item])
  }

  if (result) {
    result = result.slice(1)
  }

  return result
}
const getRequest = async (url, params = {}, headerParams = {}) => {
  // var token = await getAccountInfo()

  // if (isEmptyObject(token)) {
  //     token = Util.getToken()
  // }
  const actoken = sessionStorage.getItem('resources')
  if (!headerParams['actoken']) headerParams['actoken'] = actoken
  let options = {
    headers: {
      'Content-Type': 'application/json',
      // ...token,
      ...headerParams,
    },
  }
  const search = obj2params({
    ...params,
    // ...token
  })
  let fetchUrl = null
  if (search != '') {
    fetchUrl = Prefix.domain + Prefix.path + url + '?' + search
  } else {
    fetchUrl = Prefix.domain + Prefix.path + url
  }
  return await fetch(fetchUrl, options)
    .then((response) => {
      // console.log("response", response);
      if (response.ok) {
        return response.json()
      } else {
        const error = new Error(response.statusText)
        throw {
          err: error,
          message: '网络异常,请稍后重试! 错误代码：' + error.desc,
          code: response.status,
        }
      }
    })
    .then((res) => {
      if (res.code === 200) {
        return res.data
        // if (res.data) {
        //   return res.data
        // } else {
        //   return res
        // }
      } else {
        throw res
      }
    })
    .catch((err) => {
      console.log('aaa', err)
      if (err.code === 429) {
        throw {
          message: '请求过于频繁，请稍后再试',
          code: err.code,
        }
      } else {
        throw {
          message: err.msg || '网络不给力，请稍后再试',
          code: err.code,
        }
      }
    })
}

function goLogin() {
  Cookies.remove('Authorization')
  window.location.replace(
    '/#/login?formUrl=' + encodeURIComponent(window.location.href)
  )
}

function isEmptyObject(obj) {
  for (var key in obj) {
    return false
  }
  return true
}

const putRequest = async (url, data) => {
  console.log('putRequest', data)
  let options = {
    method: 'POST',
    body: data,
  }
  return await fetch(url, options)
    .then((response) => {
      if (response.ok) {
        return {}
      } else {
        throw {}
      }
    })
    .catch((err) => {
      throw {
        message: '网络不给力',
      }
    })
}

const fetchFromEventSource = ({
  url = '',
  method = 'POST',
  headers = {},
  body = {},
  onError = () => {},
  onSuccess = () => {},
  onDone = () => {},
  onOpen = () => {},
}) => {
  const actoken = sessionStorage.getItem('resources')
  if (!headers['actoken']) headers['actoken'] = actoken
  fetch(Prefix.domain + url, {
    method,
    headers: {
      ...headers,
      hmtoken: 'xxxx-hmhl-xxxx',
      Accept: '*/*',
      'Accept-Encoding': 'gzip, deflate, br',
      'User-Agent': 'PostmanRuntime-ApipostRuntime/1.1.0',
      Connection: 'keep-alive',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...body,
    }),
  })
    .then(async (response) => {
      // 检查响应是否成功
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      // 返回一个可读流
      onOpen()
      const contentType = response.headers.get('content-type')
      if (contentType.indexOf('application/json') !== -1) {
        const jsonResponse = await response.json()
        if (jsonResponse.code !== 200) {
          if (jsonResponse.msg) {
            throw new Error(jsonResponse.msg)
          } else {
            throw new Error('请求出错')
          }
        }
      } else {
        return response.body
      }
    })
    .then((body) => {
      const reader = body.getReader()
      // 读取数据流
      const read = () => {
        return reader.read().then(({ done, value }) => {
          // 检查是否读取完毕
          if (done) {
            onDone()
            return
          }
          // 处理每个数据块
          const decoder = new TextDecoder()
          const text = decoder.decode(value)
          text.split('data:').forEach((item) => {
            if (item) {
              try {
                const jsonData = JSON.parse(item)
                onSuccess(jsonData)
              } catch (err) {}
            }
          })

          // 继续读取下一个数据块
          read()
        })
      }
      // 开始读取数据流
      read()
    })
    .catch((error) => {
      console.error('Fetch error:', error)
      onError(error)
    })
}

export const postFetch = postRequest
export const getFetch = getRequest
export const putFetch = putRequest
export const postFormFetch = postFormRequest
export const postFetchWithHeaderObj = postRequestWithHeaderObj
export const fetchEventSource = fetchFromEventSource
